(async function () {
  const showPartnershipBanner = await require('advantage-network');

  await showPartnershipBanner({
    headerContainer: '#header-app',
    headerClassname: 'header',
    containerClassname: 'content',
    sidenavClassname: '',
    device: 'desktop'
  });
})();
